import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

import SideBar from '../components/layout/SideBar';
import {withRouter} from 'react-router';

import Contacts from '../components/contact/Contacts';
import Opportunities from '../components/opportunity/Opportunities';

import ProtectedRoute from '../util/ProtectedRoute';

const styles = (theme) => ({
  ...theme.spreadThis
});


class home extends Component {
  render() {
    return (
      <div style={{marginLeft: "18%"}}>
        <SideBar />  
        <Grid container>
          <ProtectedRoute path={`/contacts`} component={Contacts} /> 
          <ProtectedRoute path={`/deals`} component={Opportunities} /> 
        </Grid>  
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(home));