export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const LOADING_DATA = 'LOADING_DATA';
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_USER = 'LOADING_USER';
export const SET_USER = 'SET_USER';
export const SET_CONTACTS = 'SET_CONTACTS';
export const SET_CONTACT = 'SET_CONTACT';
export const POST_CONTACT = 'POST_CONTACT';
export const EDIT_CONTACT_IN_LIST = 'EDIT_CONTACT_IN_LIST';
export const SET_CRM_OBJECT = 'SET_CRM_OBJECT';
export const SET_OPPORTUNITIES_FOR_CONTACT = 'SET_OPPORTUNITIES_FOR_CONTACT';
export const SET_OPPORTUNITY = 'SET_OPPORTUNITY';
export const SET_OPPORTUNITIES = 'SET_OPPORTUNITIES';
export const SET_OPEN_OPPORTUNITIES_FOR_CONTACT = 'SET_OPEN_OPPORTUNITIES_FOR_CONTACT';
export const SET_ALL_OPPORTUNITIES_FOR_CONTACT = 'SET_ALL_OPPORTUNITIES_FOR_CONTACT';
export const SET_NOTES_FOR_CONTACT = 'SET_NOTES_FOR_CONTACT';
export const POST_NOTE_FOR_CONTACT = 'POST_NOTE_FOR_CONTACT';
export const DELETE_NOTE = 'DELETE_NOTE';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const SET_CONTACTS_FROM_SEARCH = 'SET_CONTACTS_FROM_SEARCH';
export const POST_OPPORTUNITY = 'POST_OPPORTUNITY';
export const POST_OPPORTUNITY_FOR_CONTACT = 'POST_OPPORTUNITY_FOR_CONTACT';
export const SET_STAGES = 'SET_STAGES';
export const LOADING_CONTACT_LIST_DATA = 'LOADING_CONTACT_LIST_DATA';
export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export const SET_CONTACT_DETAIL_RECORD = 'SET_CONTACT_DETAIL_RECORD';
export const LOADING_NOTES_DATA = 'LOADING_NOTES_DATA';
export const DELETE_OPPORTUNITY = 'DELETE_OPPORTUNITY';
export const SET_OPPORTUNITY_RECORD = 'SET_OPPORTUNITY_RECORD';