import React, { Component } from 'react';
import { connect } from 'react-redux';
import {withRouter} from 'react-router';
import withStyles from '@material-ui/core/styles/withStyles';
import OpportunityBoard from './OpportunityBoard';

const styles = (theme) => ({
    ...theme.spreadThis
});

class Opportunities extends Component {
    render() {
        return(
            <div style={{width: "100%", height: "99.5vh", overflowX: "scroll", marginRight: 10}}>
                <OpportunityBoard />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    
});

export default withRouter(connect(
    mapStateToProps,
  )(withStyles(styles)(Opportunities)));