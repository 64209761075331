import {
    SET_CONTACTS,
    LOADING_CONTACT_LIST_DATA,
    SET_CONTACT,
    POST_CONTACT,
    EDIT_CONTACT_IN_LIST,
    DELETE_CONTACT,
    SET_UNAUTHENTICATED,
    SET_CONTACTS_FROM_SEARCH
  } from '../types';
  
  const initialState = {
    contacts: [],
    contact: {},
    loading: false,
    orderByField: 'createdAt',
    sortOrder: 'desc',
    page: 0
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_UNAUTHENTICATED:
        return {
          ...state, ...initialState
        };
      case LOADING_CONTACT_LIST_DATA:
        return {
          ...state,
          loading: true
        };
      case DELETE_CONTACT:
        let index = state.contacts.findIndex(
          (contact) => contact.contactId === action.payload
        );
        state.contacts.splice(index, 1);
        return {
          ...state
        };
      case  SET_CONTACTS_FROM_SEARCH:
        action.payload.data.forEach(function(contact) {
            contact.contactId = contact.objectID;
        });
        return {
          ...state,
          contacts: action.payload.data,
          loading: false
        };
      case SET_CONTACTS:
        action.payload.data.forEach(function(contact) {
            contact.contactId = contact.objectID;
        });

        const contacts = ((state.sortOrder !== action.payload.sortOrder) || 
          (state.orderByField !== action.payload.orderByField)) ? 
            [...action.payload.data] : [...state.contacts, ...action.payload.data];
        
        const uniqueContacts = contacts.filter((v,i,a)=>a.findIndex(t=>(t.contactId === v.contactId))===i)

        if (action.payload.sortOrder !== state.sortOrder ||
            action.payload.orderByField !== state.orderByField) {
              action.payload.page = 0;
        }

        return {
          ...state,
          page: action.payload.page,
          contacts: uniqueContacts,
          orderByField: action.payload.orderByField,
          sortOrder: action.payload.sortOrder,
          loading: false
        };
      case SET_CONTACT:
          return {
            ...state,
            contact: action.payload
          };
      case POST_CONTACT:
          return {
            ...state,
            contacts: [action.payload, ...state.contacts]
          };
      case EDIT_CONTACT_IN_LIST:
          let indexToReplace = state.contacts.findIndex(
            (contact) => contact.contactId === action.payload.contactId
          );
          // https://redux.js.org/recipes/structuring-reducers/immutable-update-patterns
          return {
            ...state,
            contacts: state.contacts.map((contact, index) => {
              if (index !== indexToReplace) {
                return contact
              }
              return {
                ...contact,
                ...action.payload
              }
            })
          };    
      default:
        return state;
    }
}