import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import userReducer from './reducers/userReducer';
import contactReducer from './reducers/contactReducer';
import opportunityReducer from './reducers/opportunityReducer';
import uiReducer from './reducers/uiReducer';
import uploadImageReducer from './reducers/uploadImageReducer';
import contactDetailReducer from './reducers/contactDetailReducer';
import stageReducer from './reducers/stageReducer';

import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory()

const initialState = {};

const middleware = [thunk];

const reducers = combineReducers({
  user: userReducer,
  contact: contactReducer, 
  opportunity: opportunityReducer,
  UI: uiReducer,
  stages: stageReducer,
  imageUploader: uploadImageReducer,
  contactDetails: contactDetailReducer,
  router: connectRouter(history)
}); 

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(applyMiddleware(routerMiddleware(history), ...middleware));
const store = createStore(reducers, initialState, enhancer);

export default store;