import {
    SET_CONTACTS,
    SET_CONTACT_DETAIL_RECORD,
    LOADING_CONTACT_LIST_DATA,
    UPLOAD_IMAGE,
    POST_CONTACT,
    EDIT_CONTACT_IN_LIST,
    LOADING_UI,
    SET_ERRORS,
    CLEAR_ERRORS,
    DELETE_CONTACT,
    SET_CONTACTS_FROM_SEARCH
  } from '../types';
  import axios from 'axios';

const searchCache = new Map();

export const deleteContact = (contactId, history) => (dispatch) => {
    axios
      .delete(`/contact/${contactId}`)
      .then(() => {
        searchCache.clear();
          
        dispatch({ type: DELETE_CONTACT, payload: contactId });
        history.push("/contacts");
      })
      .catch((err) => console.log(err));
};

  // Get all contacts
  export const getContacts = (start, sortOrder, orderByField) => (dispatch) => {
    dispatch({ type: LOADING_CONTACT_LIST_DATA });

    const index = "contacts_"+orderByField+"_"+sortOrder;

    axios
      .get('/search?page='+start+'&index='+index)
      .then((res) => {
        searchCache.clear();

        dispatch({
          type: SET_CONTACTS,
          payload: {"page": start, "data": res.data, "orderByField": orderByField, "sortOrder": sortOrder}
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_CONTACTS,
          payload: {"data": [], "page": start, "orderByField": orderByField, "sortOrder": sortOrder}
        });
      });
  };
  
  export const getContactsForKeyword = (keyword) => (dispatch) => {
    dispatch({ type: LOADING_CONTACT_LIST_DATA });

    let cacheExpired = false;

    if (searchCache.has(keyword)) { 
      const [results, timestamp] = searchCache.get(keyword);
      if ((Date.now() - timestamp) / 1000 <= 60) {     
        dispatch({
          type: SET_CONTACTS_FROM_SEARCH,
          payload: {"data": results}
        });
      } else {
        cacheExpired = true;
      }
    } 
    
    if (!searchCache.has(keyword) || cacheExpired) {
      axios
        .get('/search?q='+keyword+'&index=contacts')
        .then((res) => {
          searchCache.set(keyword, [res.data, Date.now()]);

          dispatch({
            type: SET_CONTACTS_FROM_SEARCH,
            payload: {"data": res.data}
          });
        })
        .catch((err) => {
          dispatch({
            type: SET_CONTACTS_FROM_SEARCH,
            payload: {"data": []}
          });
        });
    }
  };

  // Post a contact
  export const postContact = (newContact, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/contact', newContact)
      .then((res) => {
        searchCache.clear();

        dispatch({
          type: POST_CONTACT,
          payload: res.data
        });
        dispatch(clearErrors());
        history.push("/contacts/contact/"+res.data.contactId);
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  // Edit a contact
  export const editContact = (contactId, newContact, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .put('/contact/'+contactId, newContact)
      .then((res) => {
        searchCache.clear();

        dispatch({
          type: EDIT_CONTACT_IN_LIST,
          payload: res.data
        });
        dispatch({
          type: SET_CONTACT_DETAIL_RECORD,
          payload: res.data
        });
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const getContactDetails = (id) => (dispatch) => {
    axios
      .get('/contact/'+id)
      .then((res) => {
        dispatch({
          type: SET_CONTACT_DETAIL_RECORD,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_CONTACT_DETAIL_RECORD,
          payload: []
        });
      });
  };

  export const uploadImage = (formData) => (dispatch) => {
    axios.post('/contact/image', formData).then(res => {
      dispatch({
        type: UPLOAD_IMAGE,
        payload: res.data.imageUrl
      })
    })
    .catch(err => console.log(err));
  }

  export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  