import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MyButton from '../../util/MyButton';
// MUI Stuff
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
// Redux stuff
import { connect } from 'react-redux';
import { editOpportunity, clearErrors } from '../../actions/opportunityActions';
import {withRouter} from 'react-router';

const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '85%',
    top: '2%'
  }
});

class EditOpportunityDialog extends Component {
  state = {
    open: false,
    amount: this.props.opportunityRecord.amount,
    contactId: this.props.opportunityRecord.contactId,
    expiresAt: this.props.opportunityRecord.expiresAt,
    name: this.props.opportunityRecord.name,
    stage: this.props.opportunityRecord.stage,
    companyId: this.props.opportunityRecord.companyId,
    errors: {}
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
    if (!nextProps.UI.errors 
          && !nextProps.UI.loading
    ) {
      this.setState({ 
        amount: '', 
        contactId: '', 
        expiresAt: '', 
        name: '', 
        stage: '', 
        companyId: '',
        open: false, 
        errors: {} 
      });
    }
  }
  
  handleOpen = () => {
    this.setState({
      open: true,
      amount: this.props.opportunityRecord.amount,
      contactId: this.props.opportunityRecord.contactId,
      expiresAt: this.props.opportunityRecord.expiresAt,
      name: this.props.opportunityRecord.name,
      stage: this.props.opportunityRecord.stage,
      companyId: this.props.opportunityRecord.companyId,
      errors: {}
    });
  };
  
  handleClose = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {}, imageUrl: "" });
  };
  
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  handleDateChange = (date) => {
    this.setState({ expiresAt: date });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.editOpportunity(this.props.opportunityRecord.opportunityId, { 
      contactId: this.props.opportunityRecord.contactId,
      amount: this.state.amount, 
      expiresAt: this.state.expiresAt, 
      name: this.state.name, 
      stage: this.state.stage,
      companyId: this.state.companyId,
    }, this.props.history);
  };

  render() {
    const { errors } = this.state;
    const {
      classes,
      UI: { loading }
    } = this.props;
    return (
      <Fragment>
        <Tooltip placement="bottom" title="Edit this Deal" aria-label="Edit this Deal">
          <IconButton
              aria-label="Edit this Deal"
              aria-haspopup="false"
              size="small"
              onClick={this.handleOpen}
          > 
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="xs"
          disableBackdropClick
        >
          <MyButton
            tip="Close"
            onClick={this.handleClose}
            tipClassName={classes.closeButton}
          >
            <CloseIcon />
          </MyButton>
          <DialogTitle>Edit Deal</DialogTitle>
          <DialogContent>
          <form onSubmit={this.handleSubmit}>
              <TextField
                name="name"
                type="text"
                label="Deal Name"
                rows="3"
                error={errors.name ? true : false}
                helperText={errors.name}
                className={classes.textField}
                onChange={this.handleChange}
                value={this.state.name}
                fullWidth
              />
              <TextField
                name="amount"
                type="text"
                label="Amount"
                rows="3"
                error={errors.amount ? true : false}
                helperText={errors.amount}
                className={classes.textField}
                onChange={this.handleChange}
                value={this.state.amount}
                fullWidth
              />
              {
              this.props.contactId ? 
                <TextField
                    name="contact"
                    type="text"
                    label="Contact"
                    rows="3"
                    disabled
                    value={this.props.contactName}
                    className={classes.textField}
                    fullWidth
                />
                :
                <TextField
                    type="text"
                    label="Contact"
                    rows="3"
                    error={errors.contact ? true : false}
                    helperText={errors.contact}
                    className={classes.textField}
                    onChange={this.handleChange}
                    fullWidth
                />
              }
              {
              this.props.companyId ?
                <TextField
                    name="companyId"
                    type="text"
                    label="Company"
                    rows="3"
                    disabled
                    value={this.props.companyName}
                    className={classes.textField}
                    error={errors.companyId ? true : false}
                    helperText={errors.companyId}
                    onChange={this.handleChange}
                    fullWidth
                />
                :
                <TextField
                    name="companyId"
                    type="text"
                    label="Company"
                    rows="3"
                    error={errors.companyId ? true : false}
                    helperText={errors.companyId}
                    className={classes.textField}
                    onChange={this.handleChange}
                    fullWidth
                />
              }
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    name="expiresAt"
                    fullWidth
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Close Date"
                    value={this.state.expiresAt ? this.state.expiresAt : new Date()}
                    onChange={this.handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change close deal by date',
                    }}
                />
              </MuiPickersUtilsProvider>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel htmlFor="stage-native-helper">Stage</InputLabel>
                <NativeSelect
                value={this.state.stage}
                onChange={this.handleChange}
                inputProps={{
                    name: 'stage',
                    id: 'stage-native-helper',
                }}
                >
                {this.props.stages && this.props.stages.map((stage) => 
                    <option value={stage.stageName}>{stage.stageName}</option>
                )}
                </NativeSelect>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={loading}
                style={{marginTop: 10}}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={30}
                    className={classes.progressSpinner}
                  />
                )}
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

EditOpportunityDialog.propTypes = {
  editOpportunity: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI,
  stages: state.stages.stages,
});

export default withRouter(connect(
  mapStateToProps,
  { editOpportunity, clearErrors }
)(withStyles(styles)(EditOpportunityDialog)));
