import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router'

import { Provider } from 'react-redux';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import jwtDecode from 'jwt-decode';

import store, { history } from './store';

import './App.css';

import axios from 'axios';

import themeObject from './util/theme';
import ProtectedRoute from './util/ProtectedRoute';
import AuthRoute from './util/AuthRoute';

import Navbar from './components/layout/Navbar';

import signup from './pages/signup';
import login from './pages/login';
import home from './pages/home';

import { SET_AUTHENTICATED } from './types';
import { logoutUser, getUserData } from './actions/userActions';

const theme = createMuiTheme(themeObject);

axios.defaults.baseURL = 'https://us-central1-simple-crm-13c73.cloudfunctions.net/api';

const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
} 

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <ConnectedRouter history={history}> { } 
            <div className="container">
                <Switch>
                  <AuthRoute path="/login" component={login} />
                  <AuthRoute path="/signup" component={signup} />
                  <ProtectedRoute path="/" component={home} />
                </Switch>
            </div>
          </ConnectedRouter>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
