import React, { Component } from 'react';
import PropTypes from 'prop-types';

import withStyles  from '@material-ui/core/styles/withStyles';
import TimelineIcon from '@material-ui/icons/Timeline';import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import ContactOpportunitiesTable from './ContactOpportunitiesTable';
import ContactTasksTable from './ContactTasksTable';
import Link from '@material-ui/core/Link';
import Contact from './Contact';
import NewOpportunityDialog from '../opportunity/NewOpportunityDialog';
import IconButton from '@material-ui/core/IconButton';
import Notes from '../note/Notes';
// Redux
import { connect } from 'react-redux';

import { getContactDetails } from '../../actions/contactActions';
import { getOpportunitiesForContact, getOpenOpportunitiesForContact, getAllOpportunitiesForContact } from '../../actions/opportunityActions';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import DeleteContact from './DeleteContact';
import { Tooltip } from '@material-ui/core';
import EditContactDialog from './EditContactDialog';

const styles = (theme) => ({
    ...theme.spreadThis
});

class ContactDetail extends Component {  
    componentDidMount() {
        this.props.getContactDetails(this.props.match.params.id);
        this.props.getOpportunitiesForContact(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.pathname !== this.props.pathname) {
            const contactId = this.props.pathname.split("/")[3];            
            this.props.getContactDetails(contactId);
            this.props.getOpportunitiesForContact(contactId);
        }
    }

    render() {
        const hideClosedDeals = (event) => {
            event.preventDefault();
            this.props.getOpenOpportunitiesForContact();
        }

        const showClosedDeals = (event) => {
            event.preventDefault();
            this.props.getAllOpportunitiesForContact();
        }

        const { firstName, lastName, email, position, company, assignedTo, imageUrl, description} = this.props.data.contactDetails;
        const contact = {
            "firstName": firstName, 
            "lastName": lastName, 
            "company": company, 
            "position": position, 
            "imageUrl": imageUrl
        };

        return (
        <div style={{display: "flex", flexDirection: "row", flex: "1", flexWrap: "nowrap", overflowX: "auto"}}> 
            <div style={{paddingTop: 10, width: "100%", height: "100vh", overflow: "scroll", paddingLeft: 20, paddingRight: 20}}>      
                <Grid item style={{paddingTop: 7}}>
                    <Paper elevation={3} square style={{borderTopRightRadius: 10, borderTopLeftRadius: 10, marginLeft: 5, marginRight: 20, paddingLeft: 30, paddingTop: 15}}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography variant="h6" align="left">
                                    Contact Info
                                </Typography>
                            </Grid>
                            <Grid item xs={2} align="right">
                                <Grid container>
                                    <Grid item xs={7}>
                                        <EditContactDialog contactRecord={this.props.data.contactDetails} contactId={this.props.pathname.split("/")[3]} />
                                    </Grid>
                                    <Grid item xs={5} align="left">
                                        <DeleteContact contactId={this.props.pathname.split("/")[3]} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={5} align="left">
                                    <Contact contact={contact} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                


                <Grid item>
                    <Paper elevation={3} square style={{marginLeft: 5, marginRight: 20, paddingLeft: 30, paddingBottom: 10}}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item style={{marginTop: 20}}>
                                <Typography variant="h6" align="left">
                                    Tasks
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container>
                            
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item>
                    <Paper elevation={3} square style={{borderBottomRightRadius: 10, borderBottomLeftRadius: 10, paddingBottom: 10, marginLeft: 5, marginRight: 20, paddingLeft: 30}}>
                        <Grid container direction="row" alignItems="center">
                            <Grid container>
                                <Grid item xs={10} style={{marginTop: 20}}>
                                    <Typography variant="h6" align="left">
                                        Deals
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} align="right" style={{marginTop: 20}}>
                                    <Grid container>
                                        <Grid item xs={7} align="right">
                                            {!this.props.data.areClosedOpportunitiesHidden ?
                                                <Link href="#" color="secondary" underline="none" onClick={hideClosedDeals}>
                                                    <Tooltip aria-label="Show Closed Deals" title="Show Closed Deals" placement="bottom">
                                                        <IconButton
                                                            aria-label="Hide Closed Opportunities"
                                                            aria-haspopup="false"
                                                            size="small"
                                                        >        
                                                            <VisibilityOffIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Link>
                                            :
                                                <Link href="#" color="primary" underline="none" onClick={showClosedDeals}>
                                                    <Tooltip aria-label="Hide Closed Deals" title="Hide Closed Deals" placement="bottom">
                                                        <IconButton
                                                            aria-label="Show Closed Opportunities"
                                                            aria-haspopup="false"
                                                            size="small"
                                                        > 
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Link>
                                            }
                                        </Grid>
                                        <Grid item xs={5} align="left">
                                            <Tooltip aria-label="Add New Deal" title="Add New Deal" placement="bottom">
                                                <IconButton
                                                    aria-label="Add a deal for this contact"
                                                    aria-haspopup="false"
                                                    size="small"
                                                >   
                                                    <NewOpportunityDialog 
                                                        contactId={this.props.match.params.id} 
                                                        contactName={this.props.data.contactDetails.firstName + " " + this.props.data.contactDetails.lastName} 
                                                        companyId="abc123456" 
                                                        companyName="appoptima.com" 
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                        <ContactOpportunitiesTable contactId={this.props.pathname.split("/")[3]} />
                    </Paper>
                </Grid>
                
                
                
                <Grid item style={{paddingTop: 30, paddingLeft: 10}}>
                    <Grid container direction="row" alignItems="center">
                        <Grid item style={{paddingRight: 2}}>
                            <TimelineIcon />
                        </Grid>
                        <Grid item>
                        <Typography variant="h6" align="left">
                            Activity
                        </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Box style={{marginBottom: 10, paddingRight: 8}}>
                    <Grid item style={{paddingTop: 5}}>
                        <Notes contactId={this.props.pathname.split("/")[3]}/>
                    </Grid> 
                </Box>
            </div>
        </div>
        )
    }
}

ContactDetail.propTypes = {
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    getContactDetails: PropTypes.func.isRequired
  };
  
const mapStateToProps = (state) => ({
    user: state.user,
    data: state.contactDetails,
    opportunities: state.contactDetails.processedOpportunitiesForContact,
    pathname: state.router.location.pathname,
    ui: state.UI
});
  
export default connect(mapStateToProps, {getContactDetails, getOpenOpportunitiesForContact, getAllOpportunitiesForContact, getOpportunitiesForContact})(withStyles(styles)(ContactDetail));