import {
    LOADING_DATA,
    SET_STAGES
  } from '../types';
  import axios from 'axios';

  
  // Get all stages
  export const getStages = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
      .get('/stages')
      .then((res) => {
        dispatch({
          type: SET_STAGES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_STAGES,
          payload: []
        });
      });
  };
  