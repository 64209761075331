import {
    SET_OPPORTUNITIES,
    LOADING_DATA,
    SET_OPPORTUNITY,
    POST_OPPORTUNITY,
    DELETE_OPPORTUNITY
  } from '../types';
  
  const initialState = {
    opportunities: [],
    opportunity: {},
    loading: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_DATA:
        return {
          ...state,
          loading: true
        };
      case SET_OPPORTUNITIES:
        return {
          ...state,
          opportunities: action.payload,
          loading: false
        };
      case DELETE_OPPORTUNITY:
          let index = state.opportunities.findIndex(
            (opportunity) => opportunity.opportunityId === action.payload
          );
          state.opportunities.splice(index, 1);
          return {
            ...state
        };
      case SET_OPPORTUNITY:
          return {
            ...state,
            opportunity: action.payload
          };
      case POST_OPPORTUNITY:
          return {
            ...state,
            opportunities: [action.payload, ...state.opportunities]
          };
      default:
        return state;
    }
}