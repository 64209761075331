import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

const styles = {
  card: {
    position: 'relative',
    display: 'flex',
    marginTop: 10,
    marginLeft: 10,
    maxWidth: 375
  },
  image: {
    minWidth: 100,
    objectFit: 'cover'
  },
  content: {
    padding: 5,
    objectFit: 'cover'
  }
};

class Contact extends Component {
  render() {
    dayjs.extend(relativeTime);
    const {
      contact: {
        firstName,
        lastName,
        position,
        imageUrl,
        contactId,
        company
      }
    } = this.props;

    return (
      <ListItem 
        button={this.props.variant === "button"} 
        selected={this.props.active} 
        component={this.props.variant === "button" ? Link : ""} 
        to={`/contacts/contact/${contactId}`}
      >
        <ListItemAvatar>
          <Avatar onClick={this.handleEditContactImage} src={imageUrl} alt={(firstName ? firstName : "")+ " " + (lastName ? lastName : "")} />
        </ListItemAvatar>
        <ListItemText  
          primary={(firstName ? firstName : "") + " " + (lastName ? lastName : "")} 
          secondary={position ? position + " at " + company : company} />
      </ListItem>
    );
  }
}


Contact.propTypes = {
  user: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  openDialog: PropTypes.bool
};

const mapStateToProps = (state) => ({
  user: state.user,
  contacts: state.contact,
  pathname: state.router.location.pathname
});

export default connect(mapStateToProps)(withStyles(styles)(Contact));