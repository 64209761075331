import React from "react";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import "draft-js-mention-plugin/lib/plugin.css";
import { postNoteForContact, getNotesForContact, clearErrors } from '../../actions/noteActions';
import { connect } from 'react-redux';
import NoteInput from './NoteInput';
import Note from './Note';
import './Note.css';

const styles = (theme) => ({
  ...theme.spreadThis
});

class Notes extends React.Component {
  componentDidMount() {
    this.props.getNotesForContact(this.props.contactId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.contactId !== nextProps.contactId) {
      this.props.getNotesForContact(nextProps.contactId);
    }
  }

  render() {
    const { notesForContact } = this.props.data;

    let showNotes = (
      notesForContact.map((note) => 
        <div key={note.id} style={{paddingLeft: 40}}>
          <div class="timeline">
            <Note note={note} />
          </div>
        </div>
      )
    );
    return (
      <div>
        <NoteInput />
        {showNotes}
      </div>
    );
  }
}

Notes.propTypes = {
  postNoteForContact: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  getNotesForContact: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  data: state.contactDetails,
  pathname: state.router.location.pathname,
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { postNoteForContact, clearErrors, getNotesForContact }
)(withStyles(styles)(Notes));
