import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import MyButton from '../../util/MyButton';
// MUI Stuff
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import ToolTip from '@material-ui/core/ToolTip';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import CloseIcon from '@material-ui/icons/Close';
// Redux stuff
import { connect } from 'react-redux';
import { postContact, clearErrors, uploadImage } from '../../actions/contactActions';
import {withRouter} from 'react-router';

const styles = (theme) => ({
  ...theme.spreadThis,
  submitButton: {
    position: 'relative',
    float: 'right',
  },
  progressSpinner: {
    position: 'absolute'
  },
  closeButton: {
    position: 'absolute',
    left: '85%',
    top: '2%'
  }
});

class NewContactDialog extends Component {
  state = {
    open: false,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    position: '',
    company: '',
    imageUrl: '',
    errors: {}
  };



  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
    if ((nextProps.imageUploader.imageUrl === "" 
          || nextProps.imageUploader.imageUrl === this.props.imageUploader.imageUrl)
          && !nextProps.UI.errors 
          && !nextProps.UI.loading
    ) {
      this.setState({ imageUrl: '', firstName: '', lastName: '', email: '', phone: '', position: '', company: '', open: false, errors: {} });
    }

    if (nextProps.imageUploader.imageUrl !== this.props.imageUploader.imageUrl) {
      this.setState({ imageUrl: nextProps.imageUploader.imageUrl });
    }

  }
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.props.clearErrors();
    this.setState({ open: false, errors: {}, imageUrl: "" });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.postContact({ 
      firstName: this.state.firstName, 
      lastName: this.state.lastName, 
      email: this.state.email, 
      phone: this.state.phone, 
      position: this.state.position, 
      company: this.state.company,
      imageUrl: this.state.imageUrl
    }, this.props.history);
  };

  handleContactImageChange = (event) => {
    event.preventDefault();
    const image = event.target.files[0];
    const formData = new FormData();
    formData.append("image", image, image.name);
    this.props.uploadImage(formData);
  };

  handleEditContactImage = (event) => {
    event.preventDefault();
    const fileInput = document.getElementById("contactImage");
    fileInput.click();
  };

  render() {
    const { errors } = this.state;
    const {
      classes,
      UI: { loading }
    } = this.props;
    return (
      <Fragment>
        <Tooltip position="bottom" title="Add a new Contact" aria-label="Add a new contact">
          <IconButton
            aria-label="Add a new Contact"
            aria-haspopup="false"
            size="small"
            style={{marginRight: 12}}
            onClick={this.handleOpen}
          >  
          <AddIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="xs"
          disableBackdropClick
        >
          <MyButton
            tip="Close"
            onClick={this.handleClose}
            tipClassName={classes.closeButton}
          >
            <CloseIcon />
          </MyButton>
          <DialogTitle>New Contact</DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <input 
                type="file" 
                id="contactImage" 
                onChange={this.handleContactImageChange} 
                hidden="hidden"
              />
              <Grid container>
                <Grid item xs={5}>
      
                </Grid>
                <Grid item xs={1} align="right">
                  <Avatar 
                    src={this.state.imageUrl}
                    style={{
                      width: "60px",
                      height: "60px",
                    }}  
                  />
                </Grid>
                <Grid item xs={1} style={{marginTop: 5, marginLeft: 10}}>
                  <ToolTip title="Upload New Contact Picture" placement="bottom-center">
                    <IconButton aria-label="Upload New Contact Picture" 
                      onClick={this.handleEditContactImage} 
                      className="button"
                      style={{ marginTop: 25, backgroundColor: 'transparent' }} >
                      <EditIcon fontSize="small" color="primary" />
                    </IconButton>
                  </ToolTip>
                </Grid>
                <Grid item xs={5}>
      
                </Grid>
              </Grid>
              
              <TextField
                name="firstName"
                type="text"
                label="First Name"
                rows="3"
                error={errors.firstName ? true : false}
                helperText={errors.firstName}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="lastName"
                type="text"
                label="Last Name"
                rows="3"
                error={errors.lastName ? true : false}
                helperText={errors.lastName}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="email"
                type="text"
                label="Email"
                rows="3"
                error={errors.email ? true : false}
                helperText={errors.email}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="phone"
                type="text"
                label="Phone Number"
                rows="3"
                error={errors.phone ? true : false}
                helperText={errors.phone}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="position"
                type="text"
                label="Position"
                rows="3"
                error={errors.position ? true : false}
                helperText={errors.position}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <TextField
                name="company"
                type="text"
                label="Company"
                rows="3"
                error={errors.company ? true : false}
                helperText={errors.company}
                className={classes.textField}
                onChange={this.handleChange}
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={loading}
              >
                Submit
                {loading && (
                  <CircularProgress
                    size={30}
                    className={classes.progressSpinner}
                  />
                )}
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

NewContactDialog.propTypes = {
  postContact: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  imageUploader: state.imageUploader,
  UI: state.UI,
  contacts: state.contact,
});

export default withRouter(connect(
  mapStateToProps,
  { postContact, uploadImage, clearErrors }
)(withStyles(styles)(NewContactDialog)));
