import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyButton from '../../util/MyButton';
import withStyles from '@material-ui/core/styles/withStyles';

// MUI stuff
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
// Icons
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { logoutUser } from '../../actions/userActions';

const styles = {
    appBar: {
        boxShadow: "0px 0px 0px -1px rgba(0,0,0,0.2), 0px 0px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12)",
        backgroundColor: "#fff",
    },

};

class Navbar extends Component {
  render() {
    const { authenticated, classes } = this.props;
    return (
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Toolbar className="nav-container">
          {authenticated ? (
            <Fragment>
              <MyButton tip="Logout" onClick={() => { 
                this.props.logoutUser();
                window.location.href = '/login';
              }}>
                <ExitToAppIcon style={{fill: "black"}}/>
              </MyButton>
            </Fragment>
          ) : (
            <Fragment>
              <Button color="inherit" component={Link} to="/login">
                Login
              </Button>
              <Button color="inherit" component={Link} to="/">
                Home
              </Button>
              <Button color="inherit" component={Link} to="/signup">
                Signup
              </Button>
            </Fragment>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

Navbar.propTypes = {
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps, {logoutUser})(withStyles(styles)(Navbar));