  
import {
    SET_ERRORS,
    LOADING_UI,
    LOADING_NOTES_DATA,
    SET_NOTES_FOR_CONTACT,
    POST_NOTE_FOR_CONTACT,
    CLEAR_ERRORS,
    DELETE_NOTE,
  } from '../types';
  import axios from 'axios';

export const deleteNote = (noteId) => (dispatch) => {
    axios
        .delete(`/note/${noteId}`)
        .then(() => {
            dispatch({ type: DELETE_NOTE, payload: noteId });
        })
        .catch((err) => console.log(err));
};

export const postNoteForContact = (newNote) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/note', newNote)
      .then((res) => {
        dispatch({
          type: POST_NOTE_FOR_CONTACT,
          payload: res.data
        });
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
};

export const getNotesForContact = (contactId) => (dispatch) => {
    dispatch({ type: LOADING_NOTES_DATA });
    axios
        .get('/contact/' + contactId + '/notes')
        .then((res) => {
        dispatch({
            type: SET_NOTES_FOR_CONTACT,
            payload: res.data
        });
        })
        .catch((err) => {
        dispatch({
            type: SET_NOTES_FOR_CONTACT,
            payload: []
        });
        });
};

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
};