import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import DeleteOpportunity from '../opportunity/DeleteOpportunity';
import EditOpportunityDialog from '../opportunity/EditOpportunityDialog';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Title' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'stage', numeric: false, disablePadding: false, label: 'Stage' },
  { id: 'expiresAt', numeric: false, disablePadding: false, label: 'Close Date' },
  { id: 'action', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = props => {
  return (
    <Toolbar />
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = theme => ({
  root: {
    width: '95%',
    paddingRight: '5%',
  },
  paper: {
    paddingBottom: 20,
  },
  table: {
    maxHeight: 500
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

class ContactOpportunitiesTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      page: 0,
      rowsPerPage: 5
    };
  }

  render() {
    const handleRequestSort = (event, property) => {
      const isAsc = this.state.orderBy === property && this.state.order === 'asc';
      this.setState({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property
      });
    };

    const handleChangePage = (event, newPage) => {
      this.setState({page: newPage});
    };
    
    const handleChangeRowsPerPage = event => {
      this.setState({rowsPerPage: parseInt(event.target.value, 10), page: 0});
    };

    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {
          this.props.contactDetails.loading ?
          <Paper square elevation={0} className={classes.paper} style={{paddingTop: 10}}>
           Loading...
          </Paper> 
          : this.props.opportunities.length === 0  ? 
          <Paper square elevation={0} className={classes.paper} style={{paddingTop: 10}}>
            There aren't any deals associated with this contact.
          </Paper> 
          :
          <Paper square elevation={0} className={classes.paper}>
            <TableContainer>
              <Table
                size='small'
                className={classes.table}
                aria-label="Opportunities table for this contact"
              >
                <EnhancedTableHead
                  classes={classes}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={this.props.opportunities ? this.props.opportunities.length : 0}
                />
                <TableBody>
                  {stableSort(this.props.opportunities ? this.props.opportunities : [], getComparator(this.state.order, this.state.orderBy))
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((row, index) => {

                      return (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            <div style={{
                              overflow: "hidden", 
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              width: 100,
                              display: "block",
                            }}> 
                              {row.name}
                            </div>  
                          </TableCell>
                          <TableCell align="left">
                            <div style={{
                              overflow: "hidden", 
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              width: 100,
                              display: "block",
                            }}> 
                              {row.amount}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div style={{
                              overflow: "hidden", 
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              width: 100,
                              display: "block",
                            }}> 
                              {row.stage}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div style={{
                              overflow: "hidden", 
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              width: 100,
                              display: "block",
                            }}> 
                              {row.expiresAt}
                            </div>
                          </TableCell>
                          <TableCell align="left">
                            <div style={{
                              overflow: "hidden", 
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              width: 100,
                              display: "block",
                            }}> 
                              <EditOpportunityDialog opportunityRecord={row} /> 
                              <DeleteOpportunity opportunityId={row.opportunityId} />
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.props.opportunities ? this.props.opportunities.length : 0}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  opportunities: state.contactDetails.processedOpportunitiesForContact,
  pathname: state.router.location.pathname,
  contactDetails: state.contactDetails,
  ui: state.UI
});

export default connect(mapStateToProps, {

})(withStyles(useStyles)(ContactOpportunitiesTable));