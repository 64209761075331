import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleAltSharpIcon from '@material-ui/icons/PeopleAltSharp';
import MonetizationOnSharpIcon from '@material-ui/icons/MonetizationOnSharp';
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";
import SideBarFooter from './SideBarFooter';

const drawerWidth = "18%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#18202E",
    color: "#fff",
  },
  drawerContainer: {
    overflow: 'auto'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
}));


export default function ClippedDrawer() {
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Paper elevation={0} style={{backgroundColor: "#18202E"}}>
          <img src={Logo} style={{width: 50, height: 50, paddingLeft: 10, marginTop: 20, marginBottom: 20}} />
        </Paper>
        <div className={classes.drawerContainer}>
          <List>
              <ListItem selected={true} button key="Contacts" component={Link} to="/contacts">
                <ListItemIcon style={{color: "#fff  "}}><PeopleAltSharpIcon /></ListItemIcon>
                <ListItemText primary="Contacts" />
              </ListItem>

              <ListItem button key="Deals" to="/deals" component={Link}>
                <ListItemIcon style={{color: "#fff  "}}><MonetizationOnSharpIcon /></ListItemIcon>
                <ListItemText primary="Deals" />
              </ListItem>
          </List>
          <div style={{position: 'absolute', bottom: 0, width: '100%'}}>
            <SideBarFooter />
          </div>
        </div>
      </Drawer>
    </div>
  );
}