import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { getContacts } from '../actions/contactActions';

const options = [
  'Name (A-Z)',
  'Name (Z-A)',
  'Date Created',
  'Date Modified'
];

const styles = (theme) => ({
  ...theme.spreadThis
});

const ITEM_HEIGHT = 48;

class SortMenu extends Component {

  constructor(props) {
    super(props);
    this.state = {anchorEl: null};
  }

  render() {
    const open = Boolean(this.state.anchorEl);
  
    const handleClick = (event) => {
      this.setState({
        anchorEl: event.currentTarget
      });
    };

    const handleAZAscSort = () => {
      if (this.props.data.orderByField === "firstName" && this.props.data.sortOrder === "asc") {
        this.setState({
          anchorEl: null
        });
        return;
      }
      this.props.getContacts(0, "asc", "firstName");
      this.setState({
        anchorEl: null
      });
    };

    const handleAZDescSort = () => {
      if (this.props.data.orderByField === "firstName" && this.props.data.sortOrder === "desc") {
        this.setState({
          anchorEl: null
        });
        return;
      }
      this.props.getContacts(0, "desc", "firstName");
      this.setState({
        anchorEl: null
      });
    }

    const handleCreatedDateDescSort = () => {
      if (this.props.data.orderByField === "createdAt") {
        this.setState({
          anchorEl: null
        });
        return;
      }
      this.props.getContacts(0, "desc", "createdAt");
      this.setState({
        anchorEl: null
      });
    }

    const handleClose = () => {
      this.setState({
        anchorEl: null
      });
    };

    const handleModifiedDateDescSort = () => {
      if (this.props.data.orderByField === "modifiedAt") {
        this.setState({
          anchorEl: null
        });
        return;
      }
      this.props.getContacts(0, "desc", "modifiedAt");
      this.setState({
        anchorEl: null
      });
    }

    return (
        <span>
            <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{padding: 8}}
            >
            <SortByAlphaIcon style={{fill: "black"}}/>
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '15wh',
                },
                }}
            >
                {options.map((option) => (
                  <MenuItem key={option} onClick={option === "Name (A-Z)" ? handleAZAscSort : 
                                                  option === "Name (Z-A)" ? handleAZDescSort :
                                                  option === "Date Created" ? handleCreatedDateDescSort :
                                                  option === "Date Modified" ? handleModifiedDateDescSort :
                                                  null} selected={(option === (this.props.data.orderByField === "firstName" ? 
                                                                                (this.props.data.sortOrder === "asc" ? "Name (A-Z)" : "Name (Z-A)") 
                                                                                : this.props.data.orderByField === "modifiedAt" ? "Date Modified" : "Date Created"))}>
                    {option}
                  </MenuItem>
                ))}
            </Menu>
        </span>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.contact,
});
  
const mapActionsToProps = { getContacts };

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(SortMenu));


