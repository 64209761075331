import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';

import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {withRouter} from 'react-router';

import Contact from './Contact';
import ContactDetail from './ContactDetail';

import { getContacts, getContactsForKeyword } from '../../actions/contactActions';

import ProtectedRoute from '../../util/ProtectedRoute';
import SortMenu from '../SortMenu';

import NewContactDialog from './NewContactDialog';
import ContactListSkeleton from './ContactListSkeleton';

const styles = (theme) => ({
  ...theme.spreadThis
});


class Contacts extends Component {
  prevY = 0;

  onSearch(keyword) {
    console.log("search keyword", keyword);
    this.props.getContactsForKeyword(keyword);
  };

  componentDidMount() {
      this.props.getContacts(0, "desc", "createdAt");

      // Options
      var options = {
        root: null, // Page as root
        rootMargin: "0px",
        threshold: 0
      };
      // Create an observer
      this.observer = new IntersectionObserver(
        this.handleObserver.bind(this), //callback
        options
      );
      //Observ the `loadingRef`
      this.observer.observe(this.loadingRef);
  }

  handleObserver(entities, observer) {
    const y = entities[0].boundingClientRect.y;
    if (this.prevY > y) {
      this.props.getContacts(
        (this.props.data.page + 1), 
        this.props.data.sortOrder, 
        this.props.data.orderByField);
    }
    this.prevY = y;
  }

  render() {
    const { contacts, loading } = this.props.data;
    const { classes } = this.props;
   
    let showContacts = (
        contacts.map((contact) => 
          <div key={contact.contactId}>
              <Contact 
                variant="button"
                contact={contact} 
                active={contact.contactId === this.props.pathname.split("/")[3]} />
              <Divider />
          </div>
        )
    );

    

    return (
        <React.Fragment>
            <Grid item style={{overflow: "hidden", paddingRight: 10}}>
                <Paper elevation={7} square className={classes.splitNav}>
                <Grid container style={{paddingTop: 10}}>
                  <Grid item xs={6} align="left" style={{marginLeft: 20}}>
                    <Typography color="textSecondary" display="block" variant="subtitle1">
                      Contacts
                    </Typography>
                  </Grid>
                  <Grid item xs={5} align="right"> 
                    <NewContactDialog />
                  </Grid>
                </Grid>
                <Grid container style={{marginBottom: 10}}>
                  <Grid item xs={6} align="left" style={{marginLeft: 20}}>   
                    <Typography display="block" variant="h5">
                      All Contacts
                    </Typography>
                  </Grid>
                </Grid>  
                <Grid container style={{marginBottom: 10}}> 
                  <Grid item xs={9} align="left" style={{marginLeft: 20}}>
                    <TextField
                      label="Search"
                      id="outlined-size-small"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onKeyUp={(ev) => {
                        if (ev.target.value.length === 0 || ev.target.value.length > 3) {
                          ev.preventDefault();
                          this.onSearch(ev.target.value)
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} style={{marginLeft: 10}}>
                    <SortMenu />
                  </Grid>
                </Grid>
                <Box style={{height: "100vh", overflowY: "auto"}}>
                  <List className={classes.list} style={{paddingBottom: 135}} component="nav" aria-label="List of Contacts">
                    {showContacts}
                    <div
                      ref={loadingRef => (this.loadingRef = loadingRef)}
                    >
                      <ContactListSkeleton show={this.props.data.loading}/>
                    </div>  
                  </List> 
                </Box>   
                </Paper>
            </Grid>
            <ProtectedRoute path={`/contacts/contact/:id`} component={ContactDetail} />  
        </React.Fragment> 
    );
  }
}

Contacts.propTypes = {
  getContacts: PropTypes.func.isRequired, 
  getContactsForKeyword: PropTypes.func.isRequired, 
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  data: state.contact,
  pathname: state.router.location.pathname,
  ui: state.UI
});

export default withRouter(connect(
  mapStateToProps,
  { getContacts, getContactsForKeyword }
)(withStyles(styles)(Contacts)));