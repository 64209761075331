import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/userActions';

const options = [
  'Logout'
];

const styles = (theme) => ({
  ...theme.spreadThis
});

const ITEM_HEIGHT = 48;

class SideBarFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {anchorEl: null};
  }

  render() {
    const {
      user: {
        credentials: { firstName, lastName, imageUrl }
      }
    } = this.props;
    const open = Boolean(this.state.anchorEl);
  
    const handleClick = (event) => {
      this.setState({
        anchorEl: event.currentTarget
      });
    };

    const handleLogout = () => {
        this.props.logoutUser();
    };
  
    const handleClose = () => {
      this.setState({
        anchorEl: null
      });

    };
    return (
        <div>
        <Grid container>
            <Grid item xs={2} style={{marginLeft:10, marginBottom: 15}}>
                <Avatar alt={firstName + " " + lastName} src={imageUrl} />
            </Grid>
            <Grid item xs={6} style={{marginTop: 10, marginLeft: 15}}>
              {firstName ? 
                  firstName.charAt(0).toUpperCase() + firstName.slice(1) + " " 
                  + (lastName ? lastName.charAt(0).toUpperCase() + lastName.slice(1) : "") 
                : " "}
            </Grid>
            <Grid item xs={1} style={{marginLeft: "3"}}>
                <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                style={{padding: 8}}
                >
                <ExpandMoreIcon style={{fill: "white"}}/>
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                    }}
                >
                    {options.map((option) => (
                    <MenuItem key={option} onClick={handleLogout} selected={option === 'Pyxis'}>
                        {option}
                    </MenuItem>
                    ))}
                </Menu>
            </Grid>
        </Grid>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
});
  
const mapActionsToProps = { logoutUser };

export default connect(
    mapStateToProps,
    mapActionsToProps
)(withStyles(styles)(SideBarFooter));
