import React from "react";
import { EditorState, convertFromRaw, CompositeDecorator } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin from "draft-js-mention-plugin";
import _ from 'lodash';
import DeleteNote from './DeleteNote';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';

const styles = {
};

class Note extends React.Component {
    constructor(props) {
        super(props);
    
        this.mentionPlugin = createMentionPlugin({});
    }

    state = {
        editorState: EditorState.createEmpty(),
        suggestions: [],
    };

    onChange = editorState => {
        this.setState({ editorState });
    };

    render() {
        const plugins = [this.mentionPlugin];
        const { MentionSuggestions } = this.mentionPlugin;
        // https://github.com/draft-js-plugins/draft-js-plugins/issues/530
        const decorators = _.flattenDeep(plugins.map((plugin) => plugin.decorators));
        const decorator = new CompositeDecorator( decorators.filter((decorator, index) => index !== 1) );

        dayjs.extend(relativeTime);
        const {
          user: {
            authenticated,
            credentials: { handle }
          }
        } = this.props;
    
        const deleteButton =
          authenticated ? (
            <DeleteNote noteId={this.props.note.noteId} />
            ) : null;

        return (
            <React.Fragment>
                <div class="timeline-container right">
                    <span className="timelineAvatar">
                        <Avatar alt={this.props.note.userHandle} src={this.props.note.userImage} />
                    </span>     
                    <div class="contentHeader">
                        <div style={{paddingBottom: 30}}>
                            <div style={{float: "left", width: "95%"}}>
                                <b>{this.props.note.userHandle}</b> ({dayjs(this.props.note.createdAt).fromNow()})
                            </div> 
                        </div>
                    </div>
                    <Paper square elevation={1} className="contentNote">        
                        <Grid container>
                            <Grid item xs={11}>           
                                <Editor 
                                    readOnly
                                    editorState={
                                        EditorState.createWithContent(convertFromRaw(this.props.note.rawText), decorator) 
                                    }
                                    plugins={plugins}
                                    onChange={this.onChange}>
                                    <MentionSuggestions
                                        suggestions={this.props.note.mentions}
                                    />
                                </Editor>
                            </Grid>
                            <Grid item xs={1} style={{paddingLeft: 9}}>
                                {deleteButton}
                            </Grid>
                        </Grid>  
                    </Paper>  
                </div>
                
            </React.Fragment>               
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(withStyles(styles)(Note));
