import {
    SET_CONTACT_DETAIL_RECORD,
    SET_OPPORTUNITIES_FOR_CONTACT,
    POST_OPPORTUNITY_FOR_CONTACT,
    SET_OPEN_OPPORTUNITIES_FOR_CONTACT,
    SET_ALL_OPPORTUNITIES_FOR_CONTACT,
    LOADING_DATA,
    SET_NOTES_FOR_CONTACT,
    POST_NOTE_FOR_CONTACT,
    DELETE_NOTE,
    LOADING_NOTES_DATA,
    SET_UNAUTHENTICATED,
    DELETE_OPPORTUNITY,
    SET_OPPORTUNITY_RECORD
  } from '../types';
  
  const initialState = {
    contactDetails: [],
    notesForContact: [],
    opportunitiesForContact: [],
    openOpportunitiesForContact: [],
    processedOpportunitiesForContact: [],
    areClosedOpportunitiesHidden: false,
    loading: false,
    loadingNotedData: false,
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SET_UNAUTHENTICATED:
        return {
          ...state, ...initialState
        };
      case LOADING_DATA:
        return {
          ...state,
          loading: true
        };
      case LOADING_NOTES_DATA:
          return {
            ...state,
            loadingNotedData: true
          };
      case DELETE_NOTE:
        let index = state.notesForContact.findIndex(
          (note) => note.noteId === action.payload
        );
        state.notesForContact.splice(index, 1);
        return {
          ...state
        };
      case SET_CONTACT_DETAIL_RECORD:
        return {
          ...state,
          contactDetails: action.payload,
          loading: false
        };
      case SET_OPPORTUNITIES_FOR_CONTACT:
        return {
          ...state,
          opportunitiesForContact: action.payload,
          processedOpportunitiesForContact: action.payload,
          areClosedOpportunitiesHidden: false,
          loading: false
        };
      case SET_OPPORTUNITY_RECORD:
        // TODO replacce edited opportunity recored from list of opportunities with the new record
        let indexToReplace = state.processedOpportunitiesForContact.findIndex(
          (opportunity) => opportunity.opportunityId === action.payload.opportunityId
        );
        return {
          ...state,
          loading: false,
          processedOpportunitiesForContact: state.processedOpportunitiesForContact.map((opportunity, index) => {
            if (index !== indexToReplace) {
              return opportunity
            }
            return {
              ...opportunity,
              ...action.payload
            }
          })
        };
      case DELETE_OPPORTUNITY:
          let opportunityIdx = state.processedOpportunitiesForContact.findIndex(
            (opportunity) => opportunity.opportunityId === action.payload
          );
          state.processedOpportunitiesForContact.splice(opportunityIdx, 1);
          return {
            ...state
        };
      case POST_OPPORTUNITY_FOR_CONTACT:
          return {
            ...state,
            opportunitiesForContact: [action.payload, ...state.opportunitiesForContact],
            processedOpportunitiesForContact: [action.payload, ...state.processedOpportunitiesForContact]
          };
      case SET_NOTES_FOR_CONTACT:
        return {
          ...state,
          notesForContact: action.payload,
          loadingNotedData: false
        };
      case POST_NOTE_FOR_CONTACT:
        return {
          ...state,
          notesForContact: [action.payload, ...state.notesForContact]
        };
      case SET_OPEN_OPPORTUNITIES_FOR_CONTACT:
        const openOpportunitiesForContact = state.opportunitiesForContact.filter(
          (val) => !(val.stage.includes("Closed Won") || val.stage.includes("Closed Lost"))
        );
        return {
          ...state,
          openOpportunitiesForContact: openOpportunitiesForContact,
          processedOpportunitiesForContact: openOpportunitiesForContact,
          areClosedOpportunitiesHidden: true,
          loading: false
        };
      case SET_ALL_OPPORTUNITIES_FOR_CONTACT:
          return {
            ...state,
            processedOpportunitiesForContact: state.opportunitiesForContact,
            areClosedOpportunitiesHidden: false,
            loading: false
          };
      default:
        return state;
    }
}