import {
    SET_OPPORTUNITIES_FOR_CONTACT,
    SET_OPEN_OPPORTUNITIES_FOR_CONTACT,
    SET_ALL_OPPORTUNITIES_FOR_CONTACT,
    LOADING_DATA,
    LOADING_UI,
    POST_OPPORTUNITY,
    POST_OPPORTUNITY_FOR_CONTACT,
    SET_ERRORS,
    CLEAR_ERRORS,
    DELETE_OPPORTUNITY,
    SET_OPPORTUNITY_RECORD,
    SET_OPPORTUNITIES
  } from '../types';
  import axios from 'axios';

  export const deleteOpportunity = (opportunityId, history) => (dispatch) => {
    axios
      .delete(`/opportunity/${opportunityId}`)
      .then(() => {
          dispatch({ type: DELETE_OPPORTUNITY, payload: opportunityId });
          // history.push("/opportunities");
      })
      .catch((err) => console.log(err));
  };

  export const getOpportunitiesForContact = (id) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
      .get('/contact/'+id+'/opportunities')
      .then((res) => {
        dispatch({
          type: SET_OPPORTUNITIES_FOR_CONTACT,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_OPPORTUNITIES_FOR_CONTACT,
          payload: []
        });
      });
  };

  export const getOpportunities = () => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    axios
      .get('/opportunities')
      .then((res) => {
        dispatch({
          type: SET_OPPORTUNITIES,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_OPPORTUNITIES,
          payload: []
        });
      });
  };

  // Post an opportunity
  export const postOpportunity = (newOpportunity, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/opportunity', newOpportunity)
      .then((res) => {
        dispatch({
          type: POST_OPPORTUNITY,
          payload: res.data
        });
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  // Post an opportunity for a contact
  export const postOpportunityForContact = (newOpportunity) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .post('/opportunity', newOpportunity)
      .then((res) => {
        dispatch({
          type: POST_OPPORTUNITY_FOR_CONTACT,
          payload: res.data
        });
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const editOpportunity = (opportunityId, newOpportunity, history) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    axios
      .put('/opportunity/'+opportunityId, newOpportunity)
      .then((res) => {
        dispatch({
          type: SET_OPPORTUNITY_RECORD,
          payload: res.data
        });
        dispatch(clearErrors());
      })
      .catch((err) => {
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data
        });
      });
  };

  export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };

  export const getOpenOpportunitiesForContact = (id) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    dispatch({ type: SET_OPEN_OPPORTUNITIES_FOR_CONTACT });
  }

  export const getAllOpportunitiesForContact = (id) => (dispatch) => {
    dispatch({ type: LOADING_DATA });
    dispatch({ type: SET_ALL_OPPORTUNITIES_FOR_CONTACT });
  }