import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    margin: theme.spacing(0)
  }
}));

function ContactSkeleton(props) {
  const classes = useStyles();

  return (
    <Card elevation={0} square className={classes.card}>
      <CardHeader
        avatar={
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        }
        title={
          <Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
          />
        }
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
      />
      <Divider />
    </Card>
  );
}

export default function ContactListSkeleton(props) {
  return (
    <div style={{display: props.show ? "block" : "none"}}>
      <ContactSkeleton />
      <ContactSkeleton />
    </div>
  );
}
