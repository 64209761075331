const initialData = {
    tasks: {
      "task-1": { id: "task-1", content: "Take out the garbage." },
      "task-2": { id: "task-2", content: "Watch my favorite show" },
      "task-3": { id: "task-3", content: "Charge my phone" },
      "task-4": { id: "task-4", content: "Cook dinner" },
      "task-5": { id: "task-5", content: "Cook dinner" },
      "task-6": { id: "task-6", content: "Cook dinner" }
    },
    columns: {
      "column-1": {
        id: "column-1",
        title: "To do",
        taskIds: ["task-1"]
      },
      "column-2": {
        id: "column-2",
        title: "In progress1",
        taskIds: ["task-2"]
      },
      "column-3": {
        id: "column-3",
        title: "In progress2",
        taskIds: ["task-3"]
      },
      "column-4": {
        id: "column-4",
        title: "In progress3",
        taskIds: []
      },
      "column-5": {
        id: "column-5",
        title: "In progress4",
        taskIds: []
      },
      "column-6": {
        id: "column-6",
        title: "In progress5",
        taskIds: ["task-4"]
      },
      "column-7": {
        id: "column-7",
        title: "In progress6",
        taskIds: ["task-5", "task-6"]
      }
    },
    // Facilitate reordering of the columns
    columnOrder: [
      "column-1",
      "column-2",
      "column-3",
      "column-4",
      "column-5",
      "column-6",
      "column-7"
    ]
  };
  
  export default initialData;
  