import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

// MUI Stuff
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { deleteContact } from '../../actions/contactActions';
import {withRouter} from 'react-router';

const styles = {
  deleteButton: {
  }
};

class DeleteContact extends Component {
  state = {
    open: false
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  deleteContact = () => {
    this.props.deleteContact(this.props.contactId, this.props.history);
    this.setState({ open: false });
  };
  render() {

    return (
      <Fragment>
         <Tooltip title="Delete Contact" aria-label="Delete Contact" placement="bottom">
          <IconButton
            aria-label="Delete Contact"
            aria-haspopup="false"
            size="small"
            onClick={this.handleOpen}
          >          
            <DeleteOutline />
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>
            Are you sure you want to delete this contact ?
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.deleteContact} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

DeleteContact.propTypes = {
  deleteContact: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withRouter(connect(
  null,
  { deleteContact }
)(withStyles(styles)(DeleteContact)));