import {
    UPLOAD_IMAGE
  } from '../types';
  
  const initialState = {
    imageUrl: ""
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case UPLOAD_IMAGE:
        return {
            ...state,
            imageUrl: action.payload
        }; 
      default:
        return state;
    }
  }