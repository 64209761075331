import {
    SET_STAGES,
    LOADING_DATA
  } from '../types';
  
  const initialState = {
    stages: [],
    loading: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case LOADING_DATA:
        return {
          ...state,
          loading: true
        };
      case SET_STAGES:
        return {
          ...state,
          stages: action.payload,
          loading: false
        };
      default:
        return state;
    }
}