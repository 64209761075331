import React from "react";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { EditorState, convertToRaw, ContentState } from "draft-js";
import Editor from "draft-js-plugins-editor";
import createMentionPlugin, {
  defaultSuggestionsFilter
} from "draft-js-mention-plugin";
import editorStyles from "./editorStyles.module.css";
import mentions from "./mentions";
import "draft-js-mention-plugin/lib/plugin.css";
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { postNoteForContact, clearErrors } from '../../actions/noteActions';
import { connect } from 'react-redux';
import createInlineToolbarPlugin, {
  Separator
} from "draft-js-inline-toolbar-plugin";
import 'draft-js/dist/Draft.css';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
} from "draft-js-buttons";
import "draft-js-inline-toolbar-plugin/lib/plugin.css";

const inlineToolbarPlugin = createInlineToolbarPlugin({
  structure: [
    BoldButton,
    ItalicButton,
    UnderlineButton,
    Separator,
    HeadlineOneButton,
    HeadlineTwoButton,
    HeadlineThreeButton,
    Separator,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
  ],
});
const { InlineToolbar } = inlineToolbarPlugin;

const styles = (theme) => ({
  ...theme.spreadThis
});

class NoteInput extends React.Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
    if (!nextProps.UI.errors && !nextProps.UI.loading) {
      this.setState({ errors: {} });
    }
  }

  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin({
      positionSuggestions: (settings) => {
        return {
          left: settings.decoratorRect.left + 'px',
          top: settings.decoratorRect.top - 10 + 'px',
          display: 'block',
          transform: 'scale(1) translateY(-100%)',
          transformOrigin: '1em 0% 0px',
          transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)'
        };
      }
    });
  }

  state = {
    editorState: EditorState.createEmpty(),
    suggestions: mentions,
    errors: {},
  };

  onChange = editorState => {
    this.setState({ editorState });
  };

  onSearchChange = ({ value }) => {
    this.setState({
      suggestions: defaultSuggestionsFilter(value, mentions)
    });
  };

  focus = () => {
    this.editor.focus();
  };

  handlePostNote = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const raw = convertToRaw(contentState);
    let mentionedUsers = [];
    for (let key in raw.entityMap) {
      const ent = raw.entityMap[key];
      if (ent.type === "mention") {
        mentionedUsers.push(ent.data.mention);
      }
    }
    this.props.postNoteForContact({
      "rawText": raw,
      "mentions": mentionedUsers,
      "relatedObjId": this.props.pathname.split("/")[3]
    });

    const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
    this.setState({ editorState });
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin, inlineToolbarPlugin];
    const {
      user: {
        credentials: { firstName, lastName, imageUrl }
      }
    } = this.props;
    
    return (
      <Paper elevation={1} style={{borderRadius:10, marginBottom: 50, marginLeft: 5, marginRight: 12}}>
        <div className={editorStyles.editor} onClick={this.focus}>
          <Grid container spacing={3}>
            <Grid item xm={1}>
              <Avatar alt={firstName+" "+lastName} src={imageUrl} />
            </Grid>
            <Grid item xs={11} align="left">
              <Editor
                editorState={this.state.editorState}
                onChange={this.onChange}
                plugins={plugins}
                placeholder="Add a note..."
                ref={element => {
                  this.editor = element;
                }}
              />
            </Grid>
          </Grid> 
          
          <InlineToolbar>
          {
            // may be use React.Fragment instead of div to improve perfomance after React 16
            (externalProps) => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <Separator {...externalProps} />
                <HeadlineOneButton {...externalProps} />
                <HeadlineTwoButton {...externalProps} />
                <HeadlineThreeButton {...externalProps} />
                <Separator {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <BlockquoteButton {...externalProps} />
              </div>
            )
          }
        </InlineToolbar>
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
          />
          
        </div>
        <Button 
          style={{marginTop: 10, float: 'right'}} 
          onClick={this.handlePostNote} 
          variant="contained" 
          size="small" 
          color="primary"
        >
          Save
        </Button>
      </Paper>
    );
  }
}

NoteInput.propTypes = {
  postNoteForContact: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.contactDetails,
  pathname: state.router.location.pathname,
  UI: state.UI,
  user: state.user
});

export default connect(
  mapStateToProps,
  { postNoteForContact, clearErrors }
)(withStyles(styles)(NoteInput));
