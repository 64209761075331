const mentions = [
    {
      name: "Matthew Russell",
      avatar:
        "https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg"
    },
    {
      name: "Julian Krispel-Samsel",
      avatar: "https://avatars2.githubusercontent.com/u/1188186?v=3&s=400"
    },
    {
      name: "Jyoti Puri",
      avatar: "https://avatars0.githubusercontent.com/u/2182307?v=3&s=400"
    },
    {
      name: "Max Stoiber",
      avatar:
        "https://pbs.twimg.com/profile_images/763033229993574400/6frGyDyA_400x400.jpg"
    },
    {
      name: "Nik Graf",
      avatar: "https://avatars0.githubusercontent.com/u/223045?v=3&s=400"
    },
    {
      name: "Pascal Brandt",
      avatar:
        "https://pbs.twimg.com/profile_images/688487813025640448/E6O6I011_400x400.png"
    },
  ];
  
  export default mentions;
  